@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap');

body {
}

.mh-100 {
  min-height: 50vh;
  @include media-breakpoint-up(md) {
    min-height: 100vh;
  }
}

.product-content {
  background: transparent;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
  .product-detail .container {
    max-width: 80%;
  }
}

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  //text-transform: uppercase;
  font-weight: 800 !important;
}

.btn-primary {
  font-weight: bold;
}

#root {
  //padding-top: $calculated-navbar-height;
  padding-top: 52.5px;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm;
  }
}

.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

.checkout .when-valid {
  background-color: #000;
}

.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background-color: rgba($color: $black, $alpha: 0.75) !important;
  top: $calculated-navbar-height;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm;
  }
  &.offcanvas-collapse-right {
    background: rgba($color: $white, $alpha: 1);
    h5 {
      color: $white;
    }
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}

// NAV

.navbar-light {
  //background: white !important;
  // transition: background 0.2s;
  // // background-color: rgba($color: $white, $alpha: 0.9) !important;
  // body[data-scrolled] & {
  //   background-color: rgba($white, 0.5) !important;
  // }
}

.navbar-light .offcanvas-collapse {
  .navbar-nav .nav-item .nav-link {
    //font-family: 'Visual-Regular', $font-family-sans-serif;
    //text-transform: uppercase;
    font-size: 1.2rem;
    background: transparent;
    color: $black;
    &.nav-link-small {
      font-size: 80%;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
}

#header > .navbar {
  height: auto;
  background-image: linear-gradient($black, rgba($black, 0));
  .item-count {
    background-color: $color-accent;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }
  .last.nav-item {
    .nav-link {
      padding-right: 0;
    }
  }
  .navbar-brand {
    img {
      max-width: 300px;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      //filter: invert(1);
      display: none;
    }
    @include media-breakpoint-down(xs) {
      img {
        width: 112px;
      }
    }
  }

  .hamburger {
    padding-left: 0;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    height: 1px;
  }
}

// JUMBOTRON
.header-jumbotron {
  background-color: $dark;
  color: $white;
  background-image: url('./assets/images/header.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 75vh;
  margin-top: $calculated-navbar-height;
  //transform: translateY(-$calculated-navbar-height);
  @include media-breakpoint-down(sm) {
    //transform: translateY(-$calculated-navbar-height-sm);
    height: 50vh;
  }
}

//FIXED LAZY LOADING
.preview-image {
  visibility: hidden;
}

// MAKI

.maki.card {
  background-color: transparent;
  .card-body {
    position: absolute;
    color: $black !important;
    width: 100%;
    height: 100%;
    padding: $card-spacer-x;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    display: flex;
    .card-title {
      justify-content: center;
      align-items: center;
      span.title {
        font-weight: 800;
        font-size: 1.2rem;
        max-width: 310px;
        @extend .text-truncate;
      }
      span.sub-title {
        font-size: smaller;
        margin-bottom: 5px;
      }
      span.price {
        font-size: 1rem;
      }
    }
  }
  &:hover {
    .card-body {
      background: rgba($black, 0.8);
      color: $white !important;
      visibility: visible;
      opacity: 1;
    }
  }
}

.table {
  @extend .table-dark;
}

.table-primary,
.table-primary td {
  background-color: $color-accent !important;
  color: black;
}

.card,
.navbar-light .offcanvas-collapse {
  color: $white;
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border-radius: 0;
  padding: 0;

  //border-bottom: 1px solid rgba($white, 0.25);
  .nigiri-content {
    padding: 0;
    margin-top: 0.2rem;
    color: $white;
    @include media-breakpoint-up(md) {
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h2-font-size;
    color: $white;
    align-items: flex-start !important;
    justify-content: left;
    text-align: left;
    @include media-breakpoint-down(md) {
      font-size: $h2-font-size;
    }
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 3rem;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    font-size: 0.8em;
  }
  .title {
    font-size: $font-size-base * 1.2;
    margin-bottom: 0;
    color: $secondary;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: $font-size-sm;
    margin-bottom: 0;
    color: $gray-200;
    font-weight: normal;
    text-transform: none;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-weight: 800 !important;
    font-size: $h2-font-size !important;
    text-transform: none;
    color: $white;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
  .btn.btn-primary {
  }
}

// Products

body.product-listing-page {
  // #root {
  //   padding-top: 0;
  //   @include media-breakpoint-down(sm) {
  //     padding-top: $calculated-navbar-height-sm;
  //   }
  // }
}

.product-detail .variant-chooser-price,
.ticket-detail .variant-chooser-price {
  text-align: left;
}

.product-detail-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .product-supertitle {
    display: none;
  }
  .product-title {
    //text-transform: uppercase;
    font-weight: normal;
    text-align: left !important;
  }
  .product-subtitle {
    font-size: $h2-font-size;
    font-weight: 800;
  }
  .price {
    font-weight: normal;
  }
}

.ticket-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  h2,
  h3 {
    font-size: $font-size-base !important;
  }

  .product-supertitle {
    font-weight: normal;
    display: block;
    font-size: $font-size-base;
  }
  .product-title {
    //text-transform: uppercase;
    font-size: $h2-font-size;
    font-weight: 800;
    text-align: left !important;
  }
  .product-subtitle {
    font-weight: normal;
    font-size: $font-size-base;
  }
  .price {
    font-weight: normal;
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: none;
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
}

// Footer

.prefooter-wrapper {
  color: $black;
  background-color: $white;

  .prefooter-text {
    align-self: center;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  .prefooter-icon-list {
    align-items: center;
    flex-wrap: wrap;

    .row {
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        row-gap: 20px;
      }

      div {
        text-align: center;
        @include media-breakpoint-up(xxl) {
          flex: 0 0 16.6666666667% !important;
          max-width: 16.6666666667% !important;
        }
      }
    }

    img {
      max-width: 100px !important;
      max-height: 100px;
      aspect-ratio: 1;
    }
  }
}

#footer {
  //box-shadow: $box-shadow-inverse-xl;
  font-size: 90%;
  min-height: 1vh;
  color: $body-color;
  line-height: 1.75rem;
  a {
    color: $body-color;
    text-decoration: none;
    &:hover {
      background-color: transparent;
    }
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
    }
    margin-top: 25px;
  }
}

.offcanvas-collapse {
  @include media-breakpoint-up(md) {
    top: 8rem;
  }
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}

.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
  height: 100vh;
  padding-left: 0.5rem;
}

.when-valid {
  //background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-100;
    color: $black;
    .btn-link {
      color: $danger;
      font-weight: bold;
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: left;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// Loading Spinner
.loading-spinner-content {
  background: transparent !important;
}

#initial-spinner.spinner-boarder.text-dark {
  color: $color-accent !important;
}

// List Step Fix

ol.list-steps {
  $border-width: 0.5rem;
  li {
    &.activatable::before {
      border: $border-width solid $gray-100;
    }

    &.active::before {
      border: $border-width solid lighten($color-accent, 40%);
    }

    &.activatable::after {
      //background: lighten($activatable, $border-lighten);
      background: $gray-300;
    }

    &.active.activatable::after {
      //background: lighten($activatable, $border-lighten);
      background: lighten($color-accent, 15%);
    }
  }
}

// Home

body.landing-page {
  #main {
    min-height: 100vh;
    margin-top: -$calculated-navbar-height;
    @include media-breakpoint-down(sm) {
      margin-top: -$calculated-navbar-height-sm;
    }
  }
  .header-image {
    // width: 75%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .intro-container {
    //min-height: 100vh;
    // @include media-breakpoint-down(sm) {
    //   height: 50vh;
    // }
    flex-direction: column;
    //font-size: 90%;
    img {
      width: 60vw;
      @include media-breakpoint-up(md) {
        max-width: 25vw;
      }
    }
  }
  .live-container {
    //background-color: $color-accent;
    //min-height: 100vh;
    flex-direction: column;
    //font-size: 90%;
    //text-transform: uppercase;
    td {
      padding: 0.15rem 0.15rem 2.5rem 0.15rem;
    }
  }
  #footer {
    margin-top: 10vh !important;
    //background-color: $color-accent;
  }
}

// CART OPTIONS

.cart-option-item {
  background-color: $gray-900;
  .variant-chooser {
    h5 {
      font-size: $font-size-base;
      font-family: $headings-font-family;
    }
  }
}
